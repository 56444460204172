
import Vue from 'vue'
import Articles from './components/Articles.vue';
import Pagination from './components/Pagination.vue';
import Filters from './components/Filters.vue';
// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
	pageCount: number
	articlesPerPage: number
	totalHits: number
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	computed: {
		pageCount(){
			return this.$store.getters.pageCount;
		},
		articlesPerPage() {
			return this.$store.getters.articlesPerPage;
		},
		totalHits() {
			return this.$store.getters.totalHits;
		}
	},
	components: {
		Articles,
		Pagination,
		Filters
	}
})
