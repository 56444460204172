import { IState } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as api from '../api';

type PayloadType = 'initApp' | 'setState' | 'setCurrentPage' | 'filter' | 'search'
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})
export const setCurrentPage: ActionCreator<number> = (number) => ({
	type: 'setCurrentPage',
	payload: number
})
export const filter: ActionCreator<undefined> = (payload) => ({
	type: 'filter',
	payload: payload
})

export const search: ActionCreator<undefined> = (payload) => ({
	type: 'search',
	payload: payload,
})

// Action handlers
const searchAction: ActionHandler<undefined> = ({ commit, state }, { payload }) => {
	const year = state.years.find(y => y.selected)
	const month = state.months.find(m => m.selected)

	api.search(state.searchURL, {
		page: state.currentPage,
		yearFilter: year,
		monthFilter: month
	})
	.then(res => {
		commit({
			type: 'setTotalHits',
			payload: res.totalHits
		})
		commit({
			type: 'setArticles',
			payload: res.articles
		})
	})
	const url = new URL(window.location.href)
	url.searchParams.set('page', state.currentPage.toString())
	if(year && year.value) {
		url.searchParams.set('year', year.value.toString())
	} else {
		url.searchParams.delete('year');
	}
	if(month && month.value) {
		url.searchParams.set('month', month.value.toString())
	} else {
		url.searchParams.delete('month');
	}
	window.history.pushState({}, document.title, url.toString());
}

const filterAction: ActionHandler<undefined> = ({ commit, dispatch, state }, { payload }) => {
	commit({
		type: 'setCurrentPage',
		payload: 0
	})
	dispatch(search(undefined));
}
const setCurrentPageAction: ActionHandler<number> = ({ commit, dispatch, state }, { payload }) => {
	commit({
		type: 'setCurrentPage',
		payload
	})
	dispatch(search(undefined));
}
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
	window.addEventListener('popstate', () => location.reload());
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	setCurrentPage: setCurrentPageAction,
	filter: filterAction,
	search: searchAction,
}

export default actions;
