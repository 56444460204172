
import Vue from 'vue'
import { setCurrentPage } from '../store/actions'
interface PaginationProps {
	
}
interface PaginationMethods {
	incPage(event: Event): void;
	decPage(event: Event): void;
	setPage(event: Event, page: number): void
	activateFocus(): void
}
export default Vue.extend<{}, PaginationMethods, {}, PaginationProps>({
	name: 'Pagination',
	computed: {
		totalHits() {
			return this.$store.getters.totalHits;
		},
		articlesPerPage() {
			return this.$store.getters.articlesPerPage;
		},
		pagination() {
			const pageCount = this.$store.getters.pageCount;
			const currentPage = this.$store.getters.currentPage + 1;
			let pagination = [...new Array(pageCount)].map((i, index) => (index + 1));
			pagination = pagination.filter(page => {
				return page > (currentPage - 3) && page < (currentPage + 3);
			})
			if(currentPage > 1) {
				pagination.unshift(-1);
			}
			if(currentPage < pageCount) {
				pagination.push(-2);
			}

			return pagination;
		},
		currentPage() {
			return this.$store.getters.currentPage + 1;
		}
	},
	methods: {
		incPage(event) {
			event.preventDefault();
			this.activateFocus();
			this.$store.dispatch(setCurrentPage(this.$store.getters.currentPage + 1))
		},
		decPage(event) {
			event.preventDefault();
			this.activateFocus();
			this.$store.dispatch(setCurrentPage(this.$store.getters.currentPage - 1))
		},
		setPage(event, page) {
			event.preventDefault();
			this.activateFocus();
			this.$store.dispatch(setCurrentPage(page - 1))
		},
		activateFocus() {
			console.log('activateFocus');
			this.$store.commit({
				type: 'shouldFocus',
				payload: true
			})
		}
	}
})
