import { MutationTree, Mutation } from 'vuex';
import { IArticle, IState } from './state';
import { ActionPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}
const setCurrentPageMutation: MutationHandler<number> = (state, { payload }) => {
	state.currentPage = payload;
}
const setArticlesMutation: MutationHandler<IArticle[]> = (state, { payload }) => {
	state.articles = payload;
}
const shouldFocusMutation: MutationHandler<boolean> = (state, { payload }) => {
	state.shouldFocus = payload;
}
const setSelectedMonthMutation: MutationHandler<number> = (state, { payload }) => {
	state.months.forEach(m => {
		if(m.value == payload) {
			m.selected = true
		} else {
			m.selected = false
		}
	})
}
const setSelectedYearMutation: MutationHandler<number> = (state, { payload }) => {
	console.log(payload)
	state.years.forEach(y => {
		if(y.value == payload) {
			y.selected = true
		} else {
			y.selected = false
		}
	})
}
const setTotalHitsMutation: MutationHandler<number> = (state, { payload }) => {
	state.totalHits = payload;
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	setCurrentPage: setCurrentPageMutation,
	setArticles: setArticlesMutation,
	shouldFocus: shouldFocusMutation,
	setSelectedYear: setSelectedYearMutation,
	setSelectedMonth: setSelectedMonthMutation,
	setTotalHits: setTotalHitsMutation
}

export default mutations
