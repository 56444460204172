import { IArticle, IMonth, IYear } from '../store/state';
import axios from 'axios'

const headers = {
	'X-Requested-With': 'XMLHttpRequest',
	'Accept': 'application/json',
}
export interface ISearchParams {
    page: number
    yearFilter?: IYear
    monthFilter?: IMonth
}
export interface IReturnObject {
    articles: IArticle[]
    totalHits: number
}



export const search = (searchURL: string, params: ISearchParams): Promise<IReturnObject> => {
    return axios.post(searchURL, params, { headers })
        .then((response:any) => {
        
            return response.data
        })
        .catch((searchError:any) => {
            console.error({
                searchError
            })
            return {} as IReturnObject
        })
}