export interface IState {
	articles: IArticle[]
	totalHits: number
	currentPage: number
	articlesPerPage: number
	searchURL: string
	shouldFocus: boolean
	months: IMonth[]
	years: IYear[]
}
export interface IMonth {
	name: string,
	value?: number,
	selected: boolean,
}
export interface IYear {
	name: string
	value?: number
	selected: boolean
}
export interface IArticle {
	title: string
	preamble: string
	uri: string
	date: string,
	image: string
}
const deafultState: IState = {
	articles: [],
	totalHits: 0,
	currentPage: 0,
	articlesPerPage: 0,
	searchURL: '',
	shouldFocus: false,
	months: [],
	years: []
}

export default deafultState;
