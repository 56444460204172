import { Getter } from "vuex";
import { IState } from './state';

const articles: Getter<IState, IState> = (state) => state.articles;
const pageCount: Getter<IState, IState> = (state) => Math.ceil(state.totalHits / state.articlesPerPage);
const totalHits: Getter<IState, IState> = (state) => state.totalHits;
const articlesPerPage: Getter<IState, IState> = (state) => state.articlesPerPage;
const currentPage: Getter<IState, IState> = (state) => state.currentPage;
const shouldFocus: Getter<IState, IState> = (state) => state.shouldFocus;
const months: Getter<IState, IState> = (state) => state.months;
const years: Getter<IState, IState> = (state) => state.years;
const selectedYear: Getter<IState, IState> = (state) => state.years.find(y => y.selected)

export default {
	articles,
	pageCount,
	totalHits,
	articlesPerPage,
	currentPage,
	shouldFocus,
	months,
	years,
	selectedYear
}
